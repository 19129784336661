<template>
  <v-form ref="munkalapForm">
    <v-row class="pt-5">
      <v-col cols="12" class="pa-0">
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="10" class="py-0">
                <v-row>
                  <v-col cols="12">
                    <h1 style="text-align: center;">Új munkalap</h1>
                  </v-col>
                  <v-col cols="12">
                    <hr class="thinLine" />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-model="munkalap.azonosito"
                      autofocus
                      label="Azonosító"
                      @input="getUgyfel"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-model="munkalap.ugyfel_neve"
                      label="Ügyfél neve"
                      :disabled="!customUgyfel"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-model="munkalap.objektum_neve"
                      label="Objektum neve"
                      :disabled="!customUgyfel"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-model="munkalap.objektum_cime"
                      label="Objektum címe"
                      :disabled="!customUgyfel"
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      label="Rendszer típusa"
                      :value="azonTipusa"
                      readonly
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field
                      v-model="munkalap.keszites_datum"
                      label="Készítés dátuma"
                      type="date"
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <h3 style="text-align: center;">Munkavégzés típusa</h3>
                      </v-col>
                      <v-col
                        v-for="(x, key) in munkaTipusok"
                        :key="key"
                        cols="12"
                        md="4"
                        class="py-0"
                      >
                        <v-checkbox
                          v-model="munkalap.munkavegzes_tipusa"
                          :label="x.text"
                          :value="x.value"
                          @click="setDefaultText(x.value)"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="2" class="mt-3">
                    <v-text-field
                      v-model="munkalap.munkavegzo"
                      label="Munkavégző neve"
                      :rules="[(v) => !!v || 'Munkavégző neve kötelező!']"
                    />
                  </v-col>
                  <v-col cols="12" md="2" class="mt-3">
                    <v-text-field
                      v-model="munkalap.datum"
                      label="Dátum"
                      type="date"
                    />
                  </v-col>
                  <v-col cols="12" md="2" class="mt-3">
                    <v-select
                      v-model="munkalap.allapot"
                      label="Munkalap állapota"
                      :items="
                        isAdmin ? munkalapAllapotokAdmin : munkalapAllapotok
                      "
                    />
                  </v-col>
                  <v-col v-if="munkalap.eszkozok.length !== 0" cols="12">
                    <hr class="thinLine" />
                  </v-col>
                  <v-col v-if="munkalap.eszkozok.length !== 0" cols="12">
                    <v-row>
                      <v-col cols="12">
                        <h1 style="text-align: center;">Eszközök</h1>
                      </v-col>
                      <v-col
                        v-for="(value, key) in munkalap.eszkozok"
                        :key="key"
                        cols="12"
                        md="4"
                        class="eszkoz"
                      >
                        <v-row>
                          <v-col cols="12" md="6" class="pb-0">
                            <v-text-field
                              v-model="value.nev"
                              label="Megnevezés"
                            />
                          </v-col>
                          <v-col cols="12" md="4" class="pb-0">
                            <v-text-field
                              v-if="isAdmin"
                              v-model.number="value.egysegar"
                              label="Egységár"
                              suffix="Ft"
                            />
                          </v-col>
                          <v-col cols="12" md="2" class="mt-5">
                            <v-icon color="error" @click="removeEszkoz(key)">
                              delete
                            </v-icon>
                          </v-col>
                          <v-col cols="12" md="6" class="pt-0">
                            <v-row>
                              <v-col
                                cols="12"
                                style="text-align: center"
                                class="py-0"
                              >
                                <h3>Felszereltek</h3>
                                <p class="mb-0">
                                  {{ value.felszerelt.length }} db
                                </p>
                              </v-col>
                              <v-col cols="12" style="text-align: center">
                                <v-btn
                                  color="green"
                                  @click="
                                    value.felszerelt.push({ gyartasi_szam: '' })
                                  "
                                >
                                  Új felszerelt
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row
                              v-for="(item, key) in value.felszerelt"
                              :key="`fel${key}`"
                            >
                              <v-col cols="12" md="10">
                                <v-text-field
                                  v-model="item.gyartasi_szam"
                                  label="Gyártási szám"
                                  :rules="[
                                    (v) =>
                                      !!v || 'Nem lehet üres a gyártási szám!',
                                  ]"
                                />
                              </v-col>
                              <v-col cols="12" md="2">
                                <v-icon
                                  class="mt-5"
                                  color="error"
                                  @click="value.felszerelt.splice(key, 1)"
                                  >delete</v-icon
                                >
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" md="6" class="pt-0">
                            <v-row>
                              <v-col
                                cols="12"
                                style="text-align: center"
                                class="py-0"
                              >
                                <h3>Leszereltek</h3>
                                <p class="mb-0">
                                  {{ value.leszerelt.length }} db
                                </p>
                              </v-col>
                              <v-col cols="12" style="text-align: center">
                                <v-btn
                                  color="grey"
                                  @click="
                                    value.leszerelt.push({ gyartasi_szam: '' })
                                  "
                                >
                                  Új leszerelt
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row
                              v-for="(item, key) in value.leszerelt"
                              :key="`le${key}`"
                            >
                              <v-col cols="12" md="10">
                                <v-text-field
                                  v-model="item.gyartasi_szam"
                                  label="Gyártási szám"
                                  :rules="[
                                    (v) =>
                                      !!v || 'Nem lehet üres a gyártási szám!',
                                  ]"
                                />
                              </v-col>
                              <v-col cols="12" md="2">
                                <v-icon
                                  class="mt-5"
                                  color="error"
                                  @click="value.leszerelt.splice(key, 1)"
                                  >delete</v-icon
                                >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <hr class="thinLine" />
                  </v-col>
                  <v-col cols="12" :md="isAdmin ? 4 : 6">
                    <v-textarea
                      v-model="munkalap.munka_leirasa"
                      label="Munka leírása"
                    />
                  </v-col>
                  <v-col cols="12" :md="isAdmin ? 4 : 6">
                    <v-textarea
                      v-model="munkalap.megjegyzes"
                      label="Megjegyzés"
                    />
                  </v-col>
                  <v-col v-if="isAdmin" cols="12" md="4">
                    <v-textarea
                      v-model="munkalap.egyeb_megjegyzes"
                      label="Egyéb megjegyzés"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="munkalap.munka_kezdese"
                      label="Munka megkezdésének ideje"
                      type="time"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="munkalap.munka_befejezese"
                      label="Munka befejezésének ideje"
                      type="time"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      v-model="munkalap.kiszallas_tavolsaga"
                      label="Kiszállás távolsága"
                      suffix="km"
                    />
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field
                      disabled
                      :value="computedMunkavegzok"
                      label="Munkavégzők száma"
                      suffix="fő"
                    />
                  </v-col>
                  <v-col v-if="isAdmin" cols="12" md="2">
                    <v-text-field
                      v-if="munkalap.szamitas === 0"
                      :value="computedAnyagar"
                      label="Anyagár"
                      suffix="Ft"
                      disabled
                    />
                    <v-text-field
                      v-else
                      value="0"
                      label="Anyagár"
                      suffix="Ft"
                      disabled
                    />
                  </v-col>
                  <v-col v-if="isAdmin" cols="12" md="2">
                    <v-text-field
                      v-if="munkalap.szamitas === 0"
                      v-model.number="munkalap.munkadij"
                      label="Munkadíj"
                      suffix="Ft"
                    />
                    <v-text-field
                      v-else
                      value="0"
                      label="Munkadíj"
                      suffix="Ft"
                      disabled
                    />
                  </v-col>
                  <v-col v-if="isAdmin" cols="12" md="2">
                    <v-text-field
                      v-if="munkalap.szamitas === 0"
                      v-model.number="munkalap.kiszallas"
                      label="Kiszállás"
                      suffix="Ft"
                    />
                    <v-text-field
                      v-else
                      value="0"
                      label="Kiszállás"
                      suffix="Ft"
                      disabled
                    />
                  </v-col>
                  <v-col v-if="isAdmin" cols="12" md="2">
                    <v-text-field
                      v-if="munkalap.szamitas === 0"
                      label="Összesen"
                      suffix="Ft"
                      readonly
                      disabled
                      :value="
                        parseInt(computedAnyagar) +
                          parseInt(munkalap.munkadij) +
                          parseInt(munkalap.kiszallas)
                      "
                    />
                    <v-text-field
                      v-else
                      v-model="munkalap.osszesen"
                      label="Összesen"
                      suffix="Ft"
                    />
                  </v-col>
                  <v-col v-if="isAdmin" cols="12" md="2">
                    <v-select
                      v-model="munkalap.alairas"
                      label="Aláírás"
                      :items="alairasok"
                    />
                  </v-col>
                  <v-col v-if="isAdmin" cols="12" md="2">
                    <v-select
                      v-model="munkalap.szamitas"
                      label="Számítás"
                      :items="szamitasOptions"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="py-1"
                style="align-self: flex-start;"
              >
                <v-row>
                  <v-col cols="12" class="py-1 d-none d-sm-flex">
                    <v-btn :block="true" color="info" @click="$router.go(-1)">
                      Vissza
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="py-1 d-none d-sm-flex">
                    <v-btn
                      :block="true"
                      color="success"
                      :disabled="disableSaveButton"
                      @click="handleSubmit"
                    >
                      Mentés
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="py-1">
                    <v-btn :block="true" color="primary" @click="newEszkoz">
                      Új eszköz
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="d-sm-none">
                <v-btn
                  color="info"
                  fab
                  bottom
                  left
                  fixed
                  @click="$router.go(-1)"
                  ><v-icon>arrow_left</v-icon></v-btn
                >
                <v-btn
                  color="success"
                  fab
                  bottom
                  right
                  fixed
                  :disabled="disableSaveButton"
                  @click="handleSubmit"
                  ><v-icon>save</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-snackbar
        v-model="mentes"
        :timeout="3000"
        :bottom="true"
        :right="true"
        color="success"
      >
        Sikeres mentés!
      </v-snackbar>
      <v-snackbar
        v-model="hiba"
        :timeout="3000"
        :bottom="true"
        :right="true"
        color="error"
      >
        {{ hibaMsg }}
      </v-snackbar>
    </v-row>
  </v-form>
</template>

<script>
export default {
  title: 'Új munkalap',
  data() {
    return {
      mentes: false,
      hiba: false,
      hibaMsg: '',
      munkalap: {
        azonosito: '',
        datum: this.$moment().format('YYYY-MM-DD'),
        megjegyzes: '',
        munka_leirasa: '',
        eszkozok: [],
        anyagar: 0,
        munkadij: 0,
        kiszallas: 0,
        allapot: 0,
        munkavegzes_tipusa: [],
        ugyfel_neve: '',
        objektum_neve: '',
        objektum_cime: '',
        alairas: -1,
        egyeb_megjegyzes: '',
        szamitas: 0,
        osszesen: 0,
        keszites_datum: this.$moment().format('YYYY-MM-DD'),
        kulso_alairas: '',
        munkavegzok_szama: 0,
        munkavegzo: '',
      },
      munkaTipusok: [
        { text: 'Karbantartás', value: 0 },
        { text: 'Javítás', value: 1 },
        { text: 'Telepítés / Kiépítés', value: 2 },
      ],
      munkalapAllapotok: [
        { text: 'Új', value: 0 },
        { text: 'Nyitott', value: 1 },
      ],
      munkalapAllapotokAdmin: [
        { text: 'Új', value: 0 },
        { text: 'Nyitott', value: 1 },
        { text: 'Lezárt', value: 2 },
      ],
      alairasok: [
        { text: 'Nincs megadva', value: -1 },
        { text: 'Bíró Csaba', value: 0 },
        { text: 'Kuti Norbert', value: 1 },
        { text: 'Sipos Gábor', value: 2 },
        { text: 'Tiber Ákos', value: 3 },
      ],
      fromUgyfel: null,
      szamitasOptions: [
        { text: 'Automatikus', value: 0 },
        { text: 'Manuális', value: 1 },
      ],
      disableSaveButton: false,
      customUgyfel: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'ugyfelek-id') {
      next((vm) => {
        vm.fromUgyfel = from.params.id;
      });
    } else {
      next();
    }
  },
  computed: {
    azonTipusa() {
      let charAt0 = this.munkalap.azonosito.charAt(0);
      if (charAt0 === 'F') {
        return 'FGSZ';
      }
      switch (parseInt(charAt0)) {
        case 0:
        case 1:
          return 'Riasztórendszer';
        case 5:
          return 'Videórendszer';
        case 6:
          return 'Beléptetőrendszer';
        case 7:
          return 'Tűzjelzőrendszer';
        case 9:
          return 'Távfelügyeleti pont';
        default:
          return 'Hibás azonosító';
      }
    },
    isAdmin() {
      return this.$store.getters.getPrivileges.munkalap_admin;
    },
    computedAnyagar() {
      let sum = 0;
      for (const x of this.munkalap.eszkozok) {
        sum += x.egysegar * x.felszerelt.length;
      }
      return sum;
    },
    computedMunkavegzok() {
      return this.munkalap.munkavegzo.split(',').length;
    },
  },
  mounted() {
    if (this.$store.getters.getUser.id === 2565)
      this.$router.push('/munkalapok');
    if (this.fromUgyfel !== null) {
      this.munkalap.azonosito = this.fromUgyfel;
      this.getUgyfel(this.fromUgyfel);
    }
  },
  methods: {
    handleSubmit() {
      this.disableSaveButton = true;
      if (this.$refs.munkalapForm.validate()) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords = `${position.coords.latitude},${position.coords.longitude}`;
            this.$axios
              .post('munkalapok/createMunkalap', {
                ...this.munkalap,
                eszkozok: this.munkalap.eszkozok,
                munkavegzes_tipusa: this.munkalap.munkavegzes_tipusa,
                coords,
              })
              .then((response) => {
                this.$router.push('/munkalapok/' + response.data.id);
              })
              .catch(() => {
                this.hibaMsg = 'Hiba mentés közben!';
                this.hiba = true;
              });
          },
          (error) => {
            const coords = `0,0`;
            this.$axios
              .post('munkalapok/createMunkalap', {
                ...this.munkalap,
                eszkozok: this.munkalap.eszkozok,
                munkavegzes_tipusa: this.munkalap.munkavegzes_tipusa,
                coords,
              })
              .then((response) => {
                this.$router.push('/munkalapok/' + response.data.id);
              })
              .catch(() => {
                this.hibaMsg = 'Hiba mentés közben!';
                this.hiba = true;
              });
          }
        );
      } else {
        this.hibaMsg = 'Hiba mentés közben!';
        this.hiba = true;
        this.disableSaveButton = false;
      }
    },
    newEszkoz() {
      this.munkalap.eszkozok.push({
        nev: '',
        felszerelt: [],
        leszerelt: [],
        egysegar: 0,
      });
    },
    removeEszkoz(key) {
      this.munkalap.eszkozok.splice(key, 1);
    },
    getUgyfel(item) {
      if (item.length < 4) return;
      if (item === 'FGSZ') {
        this.customUgyfel = true;
        this.munkalap.ugyfel_neve = 'FGSZ Földgázszállító Zrt.';
        this.munkalap.objektum_neve = '';
        this.munkalap.objektum_cime = '';
        this.munkalap.kiszallas_tavolsaga = '2 x 0';
        return;
      }
      const getItem = item.substr(0, 4);
      this.$axios
        .get(`munkalapok/getUgyfel?item=${getItem}`)
        .then((response) => {
          if (!response.data.success) {
            this.hibaMsg = 'Nincs ilyen azonosító!';
            this.hiba = true;
            this.customUgyfel = true;
            this.munkalap.ugyfel_neve = '';
            this.munkalap.objektum_neve = '';
            this.munkalap.objektum_cime = '';
            this.munkalap.kiszallas_tavolsaga = '';
          } else {
            this.customUgyfel = false;
            const ugyfelData = response.data.ugyfel;
            this.munkalap.ugyfel_neve = ugyfelData.ugyfel;
            this.munkalap.objektum_neve = ugyfelData.objektum;
            this.munkalap.objektum_cime = `${ugyfelData.iranyitoszam} ${ugyfelData.varos}, ${ugyfelData.utcahsz}`;
            this.munkalap.kiszallas_tavolsaga = `2 x ${ugyfelData.km_tavolsag}`;
          }
        })
        .catch((err) => {
          if (err) {
            this.hibaMsg = 'Nincs ilyen azonosító!';
            this.hiba = true;
            this.customUgyfel = true;
            this.munkalap.ugyfel_neve = '';
            this.munkalap.objektum_neve = '';
            this.munkalap.objektum_cime = '';
          }
        });
    },
    setDefaultText(text) {
      if (text !== 0 && text !== 1) return;
      const inTipus = this.munkalap.munkavegzes_tipusa.includes(text);
      let outText = '';
      switch (text) {
        case 0:
          if (this.azonTipusa === 'Riasztórendszer') {
            outText = `
              Központ, bővítők, zónák, érzékelők ellenőrzése. Akkumulátor(ok) tesztelése.\n
              Átjelezés tesztelése.\n
            `;
          } else if (this.azonTipusa === 'Videórendszer') {
            outText = `
              Rögzítő(k), kamerák, felvételek ellenőrzése.\n
              Kamerák megtisztítása.\n
            `;
          } else if (this.azonTipusa === 'Beléptetőrendszer') {
            outText = `
              Vezérlő(k), belépési pontok ellenőrzése, tesztelése.\n
              Akkumulátor ellenőrzése.\n
            `;
          } else {
            outText = `${this.azonTipusa} karbantartás.\n`;
          }
          break;
        case 1:
          outText = `${this.azonTipusa} javítás.\n`;
          break;
      }
      if (inTipus) {
        if (this.munkalap.munka_leirasa.indexOf(outText) === -1)
          this.munkalap.munka_leirasa += `${outText}`;
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.v-input--radio-group__input {
  justify-content: center!important;
}

.eszkoz:nth-child(odd) {
  background: rgba(0, 0, 0, 0.1);
}
</style>
