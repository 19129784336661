<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Telefonok</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" offset-md="3" class="py-0">
                  <v-select v-model="ceg" label="Cég" :items="cegek" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="szolgaltato"
                    label="Szolgáltató"
                    :items="szolgaltatok"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="deleted"
                    label="Törölt telefonok?"
                    :items="igenNem"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="filteredList"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.nev`]="{ item }">
                  <v-edit-dialog>
                    {{ item.nev }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.nev"
                        label="Név..."
                        single-line
                        @change="saveNev(item)"
                      />
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-btn
                        :to="`/telefonok/${item.id}`"
                        color="success"
                        :block="true"
                      >
                        Adatlap
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="4" pl-1 pr-1>
                      <v-btn
                        :to="`/telefonok/${item.id}/szamlak`"
                        color="primary"
                        :block="true"
                      >
                        Számlák
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-btn
                        color="error"
                        :block="true"
                        @click="deletePhone(item.id)"
                      >
                        Törlés
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Telefonok",
  data() {
    return {
      isLoading: true,
      filter: this.$store.getters["getTelefonok"].filter,
      items: [],
      headers: [
        { text: "Azonosító", value: "id" },
        { text: "Név", value: "nev" },
        { text: "Dolgozó/Telep", value: "nevtelep" },
        { text: "Telefonszám", value: "telszam" },
        { text: "Kiadás dátuma", value: "kiadas_datuma" },
        { text: "Műveletek", value: "muveletek" }
      ],
      igenNem: [{ value: 0, text: "Nem" }, { value: 1, text: "Igen" }],
      deleted: this.$store.getters["getTelefonok"].deleted,
      szolgaltatok: [
        { text: "Nincs megadva", value: 0 },
        { text: "Vodafone", value: 1 },
        { text: "Telenor", value: 2 },
        { text: "Telekom", value: 3 }
      ],
      cegek: [
        { text: "Nincs megadva", value: 0 },
        { text: "GPS Art", value: 1 },
        { text: "M2M", value: 2 },
        { text: "Rigelcom", value: 3 },
        { text: "Telenor", value: 4 },
        { text: "Telekom", value: 5 }
      ],
      ceg: this.$store.getters["getTelefonok"].ceg,
      szolgaltato: this.$store.getters["getTelefonok"].szolgaltato
    };
  },
  computed: {
    filteredList() {
      return this.items
        .filter(item => (this.ceg !== 0 ? item.ceg === this.ceg : true))
        .filter(item =>
          this.szolgaltato !== 0 ? item.szolgaltato === this.szolgaltato : true
        );
    }
  },
  watch: {
    deleted() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  beforeDestroy() {
    this.saveInfo();
  },
  methods: {
    saveNev(data) {
      this.$axios
        .post("telefonok/updateNev", { id: data.id, nev: data.nev })
        .then(() => this.getData());
    },
    deletePhone(id) {
      this.$axios
        .post("telefonok/deleteTelefon", { id: id })
        .then(() => this.getData());
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios.get(`telefonok?deleted=${this.deleted}`).then(response => {
        this.items = response.data;
        this.isLoading = false;
      });
    },
    saveInfo() {
      this.$store.dispatch("saveTelefonok", {
        filter: this.filter,
        ceg: this.ceg,
        szolgaltato: this.szolgaltato,
        deleted: this.deleted
      });
    }
  }
};
</script>

<style lang="stylus"></style>
