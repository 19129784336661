<template>
  <v-card flat style="align-self: start;">
    <v-form ref="form">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="10" class="py-0">
              <v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">
                    Új ügyfél
                  </h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="6" offset-md="3">
                  <v-select
                    v-model="rendszerTipus"
                    label="Rendszertípus"
                    :items="rendszerTipusok"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ugyfel.azonosito"
                    label="Azonosító"
                    maxlength="4"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field v-model="ugyfel.ugyfel" label="Ügyfél" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field v-model="ugyfel.objektum" label="Objektum" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ugyfel.iranyitoszam"
                    label="Irányítószám"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field v-model="ugyfel.varos" label="Város" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ugyfel.utcahsz"
                    label="Utca, házszám"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field v-model="ugyfel.telepitette" label="Telepítő" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-select
                    v-model="ugyfel.karbcsoport"
                    label="Karbantartásért felelős"
                    :items="karbcsoportok"
                    item-text="nev"
                    item-value="id"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select
                    v-model="ugyfel.karbciklus"
                    label="Karbantartási ciklus"
                    :items="karbciklusok"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="ugyfel.karbhossz"
                    label="Karbantartás hossza"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ugyfel.telepitesdatuma"
                    label="Telepítés dátuma"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="ugyfel.szerzodes_vege"
                    label="Szerződés vége"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="ugyfel.kedvezmeny"
                    label="Kedvezmény"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="ugyfel.km_tavolsag"
                    label="Távolság (km)"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="ugyfel.megjegyzes" label="Megjegyzés" />
                </v-col>
                <v-col v-if="rendszerTipus == 1" cols="12">
                  <riaszto-component
                    v-bind="{
                      riasztoAdat: riasztoUgyfel.riaszto_adat,
                      removeZonaRiaszto,
                      removeErtesitendoRiaszto,
                      removeKezeloRiaszto,
                      removeBovitoRiaszto,
                      vannincs,
                    }"
                  />
                </v-col>
                <v-col v-else-if="rendszerTipus == 2" cols="12">
                  <video-component
                    v-bind="{
                      videoAdat: videoUgyfel.video_adat,
                      videoNetworking: videoUgyfel.video_networking,
                      ujRogzitoUser,
                      removeRogzitoUser,
                      removeKamera,
                      removeNano,
                      removeNetworking,
                      removeTartomany,
                      removeVHalozati,
                    }"
                  />
                </v-col>
                <v-col v-else-if="rendszerTipus == 3" cols="12">
                  <belepteto-component
                    v-bind="{
                      beleptetoAdat: beleptetoUgyfel.belepteto_adat,
                      removeCsatolo,
                      removeBovito,
                      removeOlvaso,
                    }"
                  />
                </v-col>
                <v-col v-else-if="rendszerTipus == 4" cols="12">
                  <tuzjelzo-component
                    v-bind="{
                      tuzjelzoAdat: tuzjelzoUgyfel.tuzjelzo_adat,
                      removeErtesitendoTuzjelzo,
                    }"
                  />
                </v-col>
                <v-col v-else-if="rendszerTipus == 5" cols="12">
                  <diszpecser-component
                    v-bind="{
                      telepData,
                      hasznaltTelepData,
                      diszpecserAzonlista:
                        diszpecserUgyfel.diszpecser_azonlista,
                      ujAzonLista,
                      removeAzonosito,
                      diszpecserSzamitogepek:
                        diszpecserUgyfel.diszpecser_szamitogepek,
                      removeSzamitogep,
                      ujSzamitogepUser,
                      removeSzamitogepUser,
                    }"
                  />
                </v-col>
                <v-col
                  v-if="[1, 2, 4, 5].includes(rendszerTipus)"
                  cols="12"
                  :class="{ hide: halozatiEszkozokFiltered.length <= 0 }"
                >
                  <v-row>
                    <v-col cols="12" style="text-align: center;" mt-5>
                      <h1>Hálózati eszközök</h1>
                    </v-col>
                    <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
                    <ugyfelek-halozati-eszkoz
                      v-for="(item, key) in halozatiEszkozokFiltered"
                      :key="key"
                      v-bind="{
                        item,
                        vkey: key,
                        removeHalozati,
                      }"
                    />
                  </v-row>
                </v-col>
                <v-col v-if="ugyfel.files.length > 0" cols="12">
                  <v-row>
                    <v-col cols="12" style="text-align: center;" mt-5>
                      <h1>Fájlok</h1>
                    </v-col>
                    <v-col cols="12" mb-1> <hr class="thinLine" /> </v-col>
                    <ugyfelek-file
                      v-for="(file, key) in ugyfel.files"
                      :key="key"
                      v-bind="{
                        file,
                        vkey: key,
                        getFile,
                        removeFile,
                        gotFile,
                      }"
                    />
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <ugyfelek-buttons
                v-bind="{
                  isMobile,
                  handleSubmit,
                  azonosito: ugyfel.azonosito,
                  ujFile,
                  nyomtatPdf,
                  nyomtatUres,
                  ujZonaRiaszto,
                  ujErtesitendoRiaszto,
                  ujNetworking,
                  ujKamera,
                  ujNano,
                  ujTartomany,
                  ujCsatolo,
                  ujBovito,
                  ujErtesitendoTuzjelzo,
                  rendszerTipus,
                  ujHalozati,
                  newUgyfel: true,
                  ujKezeloRiaszto,
                  ujBovitoRiaszto,
                  ujGepDiszpecser,
                  ujOlvaso,
                  ujVHalozati,
                }"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-snackbar
          v-model="mentes"
          :timeout="3000"
          :bottom="true"
          :right="true"
          color="success"
        >
          Sikeres mentés!
        </v-snackbar>
        <v-snackbar
          v-model="hiba"
          :timeout="3000"
          :bottom="true"
          :right="true"
          color="error"
        >
          Hiba mentés közben!
        </v-snackbar>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import riasztoComponent from './riaszto';
import videoComponent from './video';
import beleptetoComponent from './belepteto';
import tuzjelzoComponent from './tuzjelzo';
import diszpecserComponent from './diszpecser';
import ugyfelekHalozatiEszkoz from './components/ugyfelekHalozatiEszkoz';
import ugyfelekButtons from './components/ugyfelekButtons';
import ugyfelekFile from './components/ugyfelekFile';

export default {
  title: 'Új ügyfél',
  components: {
    riasztoComponent,
    videoComponent,
    diszpecserComponent,
    beleptetoComponent,
    tuzjelzoComponent,
    ugyfelekButtons,
    ugyfelekHalozatiEszkoz,
    ugyfelekFile,
  },
  data() {
    return {
      mentes: false,
      hiba: false,
      rendszerTipus: 0,
      ugyfel: {
        azonosito: '',
        megjegyzes: '',
        files: [],
      },
      halozatiEszkozok: [],
      riasztoUgyfel: {
        riaszto_adat: {
          ertesitendok: [],
          zonak: [],
          kezelok: [],
          bovitok: [],
        },
      },
      videoUgyfel: {
        video_adat: {
          nano: [],
          kamera: [],
          tartomany: [],
          vhalozati: [],
        },
        video_networking: [],
      },
      beleptetoUgyfel: {
        belepteto_adat: {
          csatolok: [],
          bovitok: [],
          olvasok: [],
        },
      },
      tuzjelzoUgyfel: {
        tuzjelzo_adat: {
          ertesitendok: [],
        },
      },
      diszpecserUgyfel: {
        diszpecser_azonlista: [],
        diszpecser_szamitogepek: [],
      },
      karbcsoportok: [],
      rendszerTipusok: [
        { text: 'Nincs megadva', value: 0 },
        { text: 'Riasztórendszer', value: 1 },
        { text: 'Videórendszer', value: 2 },
        { text: 'Beléptetőrendszer', value: 3 },
        { text: 'Tűzjelzőrendszer', value: 4 },
        { text: 'Távfelügyeleti pont', value: 5 },
      ],
      karbciklusok: [
        { text: 'Nincs megadva', value: 0 },
        { text: 'Féléves', value: 2 },
        { text: 'Negyedéves', value: 4 },
      ],
      vannincs: [{ value: 0, text: 'Nincs' }, { value: 1, text: 'Van' }],
      telepData: [],
      hasznaltTelepData: [],
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    halozatiEszkozokFiltered() {
      return this.halozatiEszkozok.filter((val) => !val.deleted);
    },
    nextRiasztoZona() {
      let zonak = [...this.riasztoUgyfel.riaszto_adat.zonak];
      return zonak.length > 0
        ? parseInt(
            zonak.sort((val, val2) => val2.zonaszam - val.zonaszam)[0].zonaszam
          ) + 1
        : 1;
    },
  },
  watch: {
    rendszerTipus() {
      switch (this.rendszerTipus) {
        case 1:
          this.getKovRiaszto();
          break;
        case 2:
          this.getKovVideo();
          break;
        case 3:
          this.getKovBelepteto();
          break;
        case 4:
          this.getKovTuzjelzo();
          break;
        case 5:
          this.getKovDiszpecser();
          break;
      }
    },
  },
  beforeMount() {
    this.getData();
    this.getTelepData();
    this.getUsedTelepData();
  },
  methods: {
    gotFile(e) {
      let formData = new FormData();
      formData.append('file', e.file);
      this.$axios.post('bizttech/upUgyfelFile', formData).then(() => {
        this.ugyfel.files[e.key].name = e.file.name;
      });
    },
    getFile(file) {
      return window.open(
        window.location.protocol == 'http:'
          ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/ugyfelek/${file}`
          : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/ugyfelek/${file}`,
        '_blank'
      );
    },
    getKovRiaszto() {
      this.$axios
        .get('bizttech/kovRiaszto')
        .then((response) => (this.ugyfel.azonosito = response.data.toString()));
    },
    getKovVideo() {
      this.$axios
        .get('bizttech/kovVideo')
        .then((response) => (this.ugyfel.azonosito = response.data.toString()));
    },
    getKovBelepteto() {
      this.$axios
        .get('bizttech/kovBelepteto')
        .then((response) => (this.ugyfel.azonosito = response.data.toString()));
    },
    getKovTuzjelzo() {
      this.$axios
        .get('bizttech/kovTuzjelzo')
        .then((response) => (this.ugyfel.azonosito = response.data.toString()));
    },
    getKovDiszpecser() {
      this.$axios
        .get('bizttech/kovDiszpecser')
        .then((response) => (this.ugyfel.azonosito = response.data.toString()));
    },
    getTelepData() {
      this.$axios
        .get('bizttech/videoTelepek')
        .then((response) => (this.telepData = response.data));
    },
    getUsedTelepData() {
      this.$axios
        .get('bizttech/hasznaltTelepek')
        .then((response) => (this.hasznaltTelepData = response.data));
    },
    getData() {
      this.$axios
        .get('karbantartasok/karbantartas_csoportok')
        .then((response) => {
          this.karbcsoportok = response.data;
        });
    },
    handleSubmit() {
      this.$axios
        .post('bizttech/newUgyfel', {
          ugyfel: this.ugyfel,
          halozatiEszkozok: this.halozatiEszkozok,
          riasztoUgyfel: this.riasztoUgyfel,
          videoUgyfel: this.videoUgyfel,
          beleptetoUgyfel: this.beleptetoUgyfel,
          tuzjelzoUgyfel: this.tuzjelzoUgyfel,
          diszpecserUgyfel: this.diszpecserUgyfel,
          tipus: this.rendszerTipus,
        })
        .then(() => this.$router.push('/ugyfelek/' + this.ugyfel.azonosito))
        .catch(() => (this.hiba = true));
    },
    nyomtatUres(type) {
      const tipusok = [
        'riasztoUres',
        'videoUres',
        'beleptetoUres',
        'tuzjelzoUres',
      ];
      this.$axios
        .post(`/pdf/${tipusok[type]}`)
        .then((response) =>
          window.open(
            window.location.protocol == 'http:'
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            '_blank'
          )
        );
    },
    nyomtatPdf(type) {
      const tipusok = [
        'riasztoNyomtat',
        'videoNyomtat',
        'beleptetoNyomtat',
        'tuzjelzoNyomtat',
      ];
      this.$axios
        .post(`/pdf/${tipusok[type]}`, { azonosito: this.ugyfel.azonosito })
        .then((response) =>
          window.open(
            window.location.protocol == 'http:'
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/pdfs/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/pdfs/${response.data.fileName}`,
            '_blank'
          )
        );
    },
    ujOlvaso() {
      this.beleptetoUgyfel.belepteto_adat.olvasok.push({
        gyarto: '',
        tipus: '',
        gyariszam: '',
        vesznyito: false,
        nyomogomb: false,
      });
    },
    ujGepDiszpecser() {
      this.diszpecserUgyfel.diszpecser_szamitogepek.push({
        processzor: '',
        memoria: '',
        tarhely: '',
        videokartya: '',
        felhasznalok: [],
        megjegyzes: '',
        azonosito: this.ugyfel.azonosito,
        monitorok: 0,
        deleted: 0,
        newSzamitogep: true,
      });
    },
    ujSzamitogepUser(key) {
      this.diszpecserUgyfel.diszpecser_szamitogepek[key].felhasznalok.push({
        felhasznalonev: '',
        jelszo: '',
        passshow: 0,
      });
    },
    ujFile() {
      this.ugyfel.files.push({ name: '', megnevezes: '', megjegyzes: '' });
    },
    ujKezeloRiaszto() {
      this.riasztoUgyfel.riaszto_adat.kezelok.push({
        gyarto: '',
        tipus: '',
        sorozatszam: '',
      });
    },
    ujBovitoRiaszto() {
      this.riasztoUgyfel.riaszto_adat.bovitok.push({
        gyarto: '',
        tipus: '',
        sorozatszam: '',
      });
    },
    ujAzonLista(newAzon) {
      if (newAzon)
        this.diszpecserUgyfel.diszpecser_azonlista.push({
          id: newAzon.azonosito,
          kozpont_id: this.ugyfel.azonosito,
          megjegyzes: '',
          diszpecser_videoUgyfelek: {
            azonosito: newAzon.azonosito,
            ugyfel: newAzon.ugyfel,
            objektum: newAzon.objektum,
            deleted: 0,
          },
          deleted: 0,
          newAzonosito: true,
        });
    },
    ujRogzitoUser(key) {
      this.videoUgyfel.video_networking[key].felhasznalok.push({
        tipus: 0,
        felhasznalonev: '',
        jelszo: '',
        nev: '',
        keresere: '',
        passshow: false,
      });
    },
    ujKamera() {
      this.videoUgyfel.video_adat.kamera.push({
        kameratipus: '',
        kameraszam: '',
        kameraip: '',
        sorozatszam: '',
        felhasznalonev: '',
        jelszo: '',
        megjegyzes: '',
        passshow: false,
      });
    },
    ujNano() {
      this.videoUgyfel.video_adat.nano.push({
        ssid: '',
        ssidpass: '',
        ip: '',
        nanouser: '',
        nanopass: '',
        nanoszam: '',
        nev: '',
        gyarto: '',
        tipus: '',
        sorozatszam: '',
        megjegyzes: '',
        nanopassshow: false,
        ssidpassshow: false,
      });
    },
    ujNetworking() {
      this.videoUgyfel.video_networking.push({
        azonosito: this.ugyfel.azonosito,
        adminpass: '',
        adminuser: '',
        belso_ip: '',
        domain: '',
        http_port: 0,
        kulso_ip: '',
        rtsp_port: 0,
        tcp_port: 0,
        udp_port: 0,
        felhasznalok: [],
        hdd_meret: '',
        idotartam: '',
        rogzito_helye: '',
        rogzito_neve: '',
        gyarto: '',
        tipus: '',
        megjegyzes: '',
        deleted: 0,
        newNetworking: false,
        adminpassshow: false,
        sorozatszam: '',
        khttp_port: 0,
        ktcp_port: 0,
      });
    },
    ujErtesitendoTuzjelzo() {
      this.tuzjelzoUgyfel.tuzjelzo_adat.ertesitendok.push({
        nev: '',
        telszam: '',
      });
    },
    ujErtesitendoRiaszto() {
      this.riasztoUgyfel.riaszto_adat.ertesitendok.push({
        nev: '',
        telszam: '',
      });
    },
    ujZonaRiaszto() {
      this.riasztoUgyfel.riaszto_adat.zonak.push({
        zonanev: '',
        zonaszam: this.nextRiasztoZona,
        tipus: '',
        gyariszam: '',
      });
    },
    ujHalozati() {
      this.halozatiEszkozok.push({
        azonosito: this.ugyfel.azonosito,
        eszkoz: '',
        ip: '',
        pass: '',
        port: '',
        service: '',
        tipus: '',
        user: '',
        deleted: 0,
        newHalozati: true,
        ssid: '',
        ssidpass: '',
        megjegyzes: '',
        ssidpassshow: false,
        passshow: false,
        sorozatszam: '',
        sim: '',
      });
    },
    ujVHalozati() {
      this.videoUgyfel.video_adat.vhalozati.push({
        gyarto: '',
        tipus: '',
        gyariszam: '',
      });
    },
    ujTartomany() {
      this.videoUgyfel.video_adat.tartomany.push({
        ip: '',
        almaszk: '',
        alapertelmezett_atjaro: '',
        kezdes: '',
        vege: '',
        dns: '',
      });
    },
    ujCsatolo() {
      this.beleptetoUgyfel.belepteto_adat.csatolok.push({
        ip: '',
        felhasznalonev: '',
        jelszo: '',
        jelszoshow: false,
        nev: '',
        cim: '',
        gyarto: '',
        tipus: '',
        sorozatszam: '',
      });
    },
    ujBovito() {
      this.beleptetoUgyfel.belepteto_adat.bovitok.push({
        nev: '',
        cim: '',
        megjegyzes: '',
        gyarto: '',
        tipus: '',
        sorozatszam: '',
      });
    },
    removeAzonosito(key) {
      this.diszpecserUgyfel.diszpecser_azonlista[key].deleted = 1;
      if (this.diszpecserUgyfel.diszpecser_azonlista[key].newAzonosito)
        this.diszpecserUgyfel.diszpecser_azonlista.splice(key, 1);
    },
    removeHalozati(key) {
      this.halozatiEszkozok[key].deleted = 1;
      if (this.halozatiEszkozok[key].newHalozati)
        this.halozatiEszkozok.splice(key, 1);
    },
    removeKamera(key) {
      this.videoUgyfel.video_adat.kamera.splice(key, 1);
    },
    removeNano(key) {
      this.videoUgyfel.video_adat.nano.splice(key, 1);
    },
    removeNetworking(key) {
      this.videoUgyfel.video_networking[key].deleted = 1;
      if (this.videoUgyfel.video_networking[key].newNetworking)
        this.videoUgyfel.video_networking.splice(key, 1);
    },
    removeErtesitendoTuzjelzo(key) {
      this.tuzjelzoUgyfel.tuzjelzo_adat.ertesitendok.splice(key, 1);
    },
    removeErtesitendoRiaszto(key) {
      this.riasztoUgyfel.riaszto_adat.ertesitendok.splice(key, 1);
    },
    removeZonaRiaszto(key) {
      this.riasztoUgyfel.riaszto_adat.zonak.splice(key, 1);
    },
    removeRogzitoUser(key, vkey) {
      this.videoUgyfel.video_networking[key].felhasznalok.splice(vkey, 1);
    },
    removeTartomany(key) {
      this.videoUgyfel.video_adat.tartomany.splice(key, 1);
    },
    removeVHalozati(key) {
      this.videoUgyfel.video_adat.vhalozati.splice(key, 1);
    },
    removeCsatolo(key) {
      this.beleptetoUgyfel.belepteto_adat.csatolok.splice(key, 1);
    },
    removeBovito(key) {
      this.beleptetoUgyfel.belepteto_adat.bovitok.splice(key, 1);
    },
    removeFile(key) {
      this.ugyfel.files.splice(key, 1);
    },
    removeKezeloRiaszto(key) {
      this.riasztoUgyfel.riaszto_adat.kezelok.splice(key, 1);
    },
    removeBovitoRiaszto(key) {
      this.riasztoUgyfel.riaszto_adat.bovitok.splice(key, 1);
    },
    removeSzamitogep(key) {
      this.diszpecserUgyfel.diszpecser_szamitogepek[key].deleted = 1;
      if (this.diszpecserUgyfel.diszpecser_szamitogepek[key].newSzamitogep)
        this.diszpecserUgyfel.diszpecser_szamitogepek.splice(key, 1);
    },
    removeSzamitogepUser(key, vkey) {
      this.diszpecserUgyfel.diszpecser_szamitogepek[key].felhasznalok.splice(
        vkey,
        1
      );
    },
    removeOlvaso(key) {
      this.beleptetoUgyfel.belepteto_adat.olvasok.splice(key, 1);
    },
  },
};
</script>
