<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Dolgozók</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" md="2" class="py-0">
                  <v-autocomplete
                    v-model="moreFilter.beosztasFilter"
                    label="Beosztás"
                    :items="beosztasok"
                    item-text="megnevezes"
                    item-value="id"
                    :filter="singleFilter"
                    @change="doGetData"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-autocomplete
                    v-model="moreFilter.regioFilter"
                    label="Régió"
                    :items="regiok"
                    item-text="megnevezes"
                    item-value="azonosito"
                    :filter="multiFilter"
                    @change="doGetData"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-autocomplete
                    v-model="moreFilter.objektumFilter"
                    label="Objektum"
                    :items="objektumok"
                    item-text="megnevezes"
                    item-value="id"
                    :filter="singleFilter"
                    :disabled="objektumDisabled"
                    @change="doGetData"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-autocomplete
                    v-model="moreFilter.cegFilter"
                    label="Cég"
                    :items="cegek"
                    item-text="megnevezes"
                    item-value="id"
                    :filter="singleFilter"
                    @change="doGetData"
                  />
                </v-col>
                <v-col cols="12" md="1" class="py-0">
                  <v-select
                    v-model="kilepett"
                    :items="igenNem"
                    label="Kilépett?"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    append-icon="search"
                    label="Keresés"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="filteredList"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :custom-filter="customFilter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:[`item.nev`]="{ item }">
                  <span>{{ item.nev }}</span>
                  <span
                    style="color: red;"
                    title="Egyszerűsített foglalkozatás"
                    >{{ item.fogltipus === 5 ? "*" : "" }}</span
                  >
                  <span style="color: red;" title="65 év fölötti">
                    {{ checkDate(item.szuletesi_datum) }}</span
                  >
                </template>
                <template v-slot:[`item.regio`]="{ item }">
                  {{ regioObjektum(item.meta_regio, item.meta_objektum) }}
                </template>
                <template v-slot:[`item.muveletek`]="{ item }">
                  <v-btn
                    color="success"
                    :block="true"
                    :to="`/dolgozok/${item.id}`"
                  >
                    Adatlap
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Dolgozók",
  data() {
    return {
      kilepett: this.$store.getters["getDolgozok"].kilepett,
      isLoading: false,
      items: [],
      filter: this.$store.getters["getDolgozok"].filter,
      headers: [
        {
          text: "Azonosító",
          value: "id",
          class: "hidden-sm-and-down"
        },
        { text: "Név", value: "nev" },
        {
          text: "Beosztás",
          value: "meta_beosztas.megnevezes",
          class: "hidden-sm-and-down"
        },
        { text: "Régió", value: "regio", class: "hidden-sm-and-down" },
        {
          text: "Cég",
          value: "meta_aktualisceg.megnevezes",
          class: "hidden-sm-and-down"
        },
        { text: "Műveletek", value: "muveletek" }
      ],
      igenNem: [{ value: false, text: "Nem" }, { value: true, text: "Igen" }],
      beosztasok: [],
      cegek: [],
      regiok: [],
      objektumok: [],
      moreFilter: { ...this.$store.getters["getDolgozok"].moreFilter }
    };
  },
  computed: {
    filteredList() {
      return this.items;
    },
    mindenDolgozo() {
      return this.$store.getters.getPrivileges.mindenDolgozo;
    },
    cUserID() {
      return this.$store.getters.getUser.id;
    },
    objektumDisabled() {
      return this.moreFilter.regioFilter == 0;
    }
  },
  watch: {
    kilepett() {
      if (this.mindenDolgozo) this.getDataAll();
      else this.getDataFilter();
    }
  },
  beforeMount() {
    this.getMeta();
    if (this.mindenDolgozo) this.getDataAll();
    else this.getDataFilter();
  },
  mounted() {
    this.items = this.$store.getters.dolgozokTable;
  },
  beforeDestroy() {
    this.saveInfo();
  },
  methods: {
    checkDate(date) {
      const d = this.$moment().diff(this.$moment(date, "YYYY-MM-DD"), "years");
      if (d >= 65) {
        return d;
      }
    },
    customFilter(value, search, item) {
      const searchString = search.toString().toLowerCase();
      return (
        item.id.toString().indexOf(search.toString()) !== -1 ||
        item.nev
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.meta_aktualisceg.megnevezes
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.meta_beosztas.megnevezes
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.meta_objektum.megnevezes
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.meta_regio
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.nyelv
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1 ||
        item.nyelvtip
          .toString()
          .toLowerCase()
          .indexOf(searchString) !== -1
      );
    },
    doGetData() {
      if (this.mindenDolgozo) this.getDataAll();
      else this.getDataFilter();
    },
    regioObjektum(regio, objektum) {
      return `${regio.azonosito} - ${regio.megnevezes} ${
        objektum.megnevezes == regio.megnevezes
          ? ""
          : ` - ${objektum.megnevezes}`
      }`;
    },
    getObjektumok() {
      this.axios
        .get(`meta/objektumok?id=${this.moreFilter.regioFilter}`)
        .then(response => {
          this.objektumok = [
            { id: 0, megnevezes: "Nincs megadva" },
            ...response.data
          ];
        });
    },
    getDataFilter() {
      this.items = [];
      this.isLoading = true;
      this.$axios
        .get(
          `dolgozok/usersFiltered?ki=${this.kilepett}&cUser=${
            this.cUserID
          }&moreFilter=${JSON.stringify(this.moreFilter)}`
        )
        .then(response => {
          this.items = response.data;
          this.$store.dispatch("setDolgozok", response.data);
          this.isLoading = false;
          this.getObjektumok();
        });
    },
    getDataAll() {
      this.items = [];
      this.isLoading = true;
      this.$axios
        .get(
          `dolgozok/usersAll?ki=${this.kilepett}&moreFilter=${JSON.stringify(
            this.moreFilter
          )}`
        )
        .then(response => {
          this.items = response.data;
          this.$store.dispatch("setDolgozok", response.data);
          this.isLoading = false;
          this.getObjektumok();
        });
    },
    saveInfo() {
      this.$store.dispatch("saveDolgozok", {
        filter: this.filter,
        kilepett: this.kilepett,
        moreFilter: { ...this.moreFilter }
      });
    },
    getMeta() {
      const cegek = this.axios.get("meta/cegek");
      const regiok = this.axios.get("meta/regiok");
      const beosztasok = this.axios.get("meta/beosztasok");
      Promise.all([cegek, regiok, beosztasok]).then(results => {
        this.cegek = [
          { id: 0, megnevezes: "Nincs megadva" },
          ...results[0].data
        ];
        this.regiok = [
          { azonosito: 0, megnevezes: "Nincs megadva" },
          ...results[1].data
        ];
        this.beosztasok = [
          { id: 0, megnevezes: "Nincs megadva" },
          ...results[2].data
        ];
      });
    },
    multiFilter(item, queryText) {
      const searchText = queryText.toString().toLowerCase();
      const itemAzon = item.azonosito.toString().toLowerCase();
      const itemText = item.megnevezes.toString().toLowerCase();
      return (
        itemAzon.indexOf(searchText) > -1 || itemText.indexOf(searchText) > -1
      );
    },
    singleFilter(item, queryText) {
      const searchText = queryText.toString().toLowerCase();
      const itemText = item.megnevezes.toString().toLowerCase();
      return itemText.indexOf(searchText) > -1;
    }
  }
};
</script>
