<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="10" class="py-0"
              ><v-row>
                <v-col cols="12">
                  <h1 style="text-align: center;">
                    {{ telefon.id }} | {{ telefon.nev }} - {{ telefon.telszam }}
                  </h1>
                </v-col>
                <v-col cols="12"> <hr class="thinLine" /> </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="telefon.azonosito"
                    label="Objektum azonosító"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select
                    v-model="telefon.nevtelep"
                    label="Telephely/Dolgozó"
                    :items="nevTelepItems"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select
                    v-model="telefon.szolgaltato"
                    label="Szolgáltató"
                    :items="szolgaltatok"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-select v-model="telefon.ceg" label="Cég" :items="cegek" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="telefon.id" label="Sorszám" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="telefon.nev" label="Név" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="telefon.telszam" label="Telefonszám" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="telefon.tipus" label="Típus" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field
                    v-model="telefon.alapdij"
                    label="Alapdíj"
                    suffix="Ft"
                  />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-text-field v-model="telefon.keszulek" label="Készülék" />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="telefon.kiadas_datuma"
                    label="Kiadás dátuma"
                    type="date"
                    max="2999-12-31"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="telefon.adatforgalom"
                    label="Adatforgalom"
                  />
                </v-col>
                <v-col cols="12" md="4" class="py-0">
                  <v-text-field
                    v-model="telefon.husegido"
                    max="2999-12-31"
                    type="date"
                    label="Hűségidő"
                  />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="telefon.pin1" label="PIN1" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="telefon.pin2" label="PIN2" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="telefon.puk1" label="PUK1" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field v-model="telefon.puk2" label="PUK2" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" class="py-0">
              <v-row>
                <v-col cols="12" class="py-1">
                  <v-btn
                    :block="true"
                    color="primary"
                    :to="`/telefonok/${$route.params.id}/szamlak`"
                  >
                    Számlák
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="info" @click="$router.go(-1)">
                    Vissza
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-1 d-none d-sm-flex">
                  <v-btn :block="true" color="success" @click="sendUpdate">
                    Mentés
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="d-sm-none">
              <v-btn color="info" fab bottom left fixed @click="$router.go(-1)"
                ><v-icon>arrow_left</v-icon></v-btn
              >
              <v-btn color="success" fab bottom right fixed @click="sendUpdate"
                ><v-icon>save</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="mentes"
      :timeout="3000"
      :bottom="true"
      :right="true"
      color="success"
    >
      Sikeres feltöltés!
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  title: "Telefon",
  data() {
    return {
      telefon: {},
      nevTelepItems: [
        { text: "Telephely", value: "Telephely" },
        { text: "Dolgozó", value: "Dolgozó" }
      ],
      szolgaltatok: [
        { text: "Nincs megadva", value: 0 },
        { text: "Vodafone", value: 1 },
        { text: "Telenor", value: 2 },
        { text: "Telekom", value: 3 }
      ],
      cegek: [
        { text: "Nincs megadva", value: 0 },
        { text: "GPS Art", value: 1 },
        { text: "M2M", value: 2 },
        { text: "Rigelcom", value: 3 },
        { text: "Telenor", value: 4 },
        { text: "Telekom", value: 5 }
      ],
      mentes: false
    };
  },
  watch: {
    deleted: function() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    sendUpdate() {
      this.$axios.post("telefonok/updateTelefon", this.telefon).then(() => {
        this.mentes = true;
      });
    },
    getData() {
      this.$axios.get(`telefonok/${this.$route.params.id}`).then(response => {
        this.telefon = response.data;
      });
    }
  }
};
</script>

<style></style>
