<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-card flat>
        <v-card-title primary-title>
          <h2>Kimutatások</h2>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row>
                <v-col cols="12" offset-md="4" md="2" class="py-0">
                  <v-select
                    v-model="szolgaltato"
                    label="Szolgáltató"
                    :items="szolgaltatok"
                  />
                </v-col>
                <v-col cols="12" md="1" class="py-0">
                  <v-select v-model="ev" :items="evItems" />
                </v-col>
                <v-col cols="12" md="2" class="py-0">
                  <v-select v-model="honap" :items="honapItems" />
                </v-col>
                <v-col cols="12" md="3" class="py-0">
                  <v-text-field
                    v-model="filter"
                    disabled
                    label="Keresés"
                    append-icon="search"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-data-table
                :items="sortedList"
                :headers="headers"
                :loading="isLoading"
                :search="filter"
                :footer-props="{ showFirstLastPage: true }"
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr
                      v-for="(item, key) in items"
                      :key="key"
                      :class="item.osszeg >= 4000 ? 'redLine' : ''"
                    >
                      <td>{{ item.nev }}</td>
                      <td>{{ item.nevtelep }}</td>
                      <td>{{ item.nev_szam }}</td>
                      <td>{{ item.alapdij }} Ft</td>
                      <td>{{ item.parkolas }} Ft</td>
                      <td>
                        {{
                          pluszKoltseg(item.alapdij, item.parkolas, item.osszeg)
                        }}
                        Ft
                      </td>
                      <td>{{ item.osszeg }} Ft</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-btn
                :block="true"
                color="success"
                :disabled="!(items.length > 0)"
                @click="generateKimutatas"
              >
                Exportálás
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  title: "Kimutatások",
  data() {
    return {
      isLoading: true,
      filter: "",
      items: [],
      headers: [
        { text: "Név", value: "nev", sortable: false },
        {
          text: "Telephely / Dolgozó",
          value: "nevtelep",
          sortable: false
        },
        { text: "Telefonszám", value: "nev_szam", sortable: false },
        { text: "Alapdíj", value: "alapdij", sortable: false },
        { text: "Parkolás", value: "parkolas", sortable: false },
        { text: "Plusz költség", value: "plusz_koltseg", sortable: false },
        { text: "Összeg", value: "osszeg", sortable: false }
      ],
      ev: this.$moment().format("YYYY"),
      honap: this.$moment().format("M"),
      honapItems: [
        { text: "Január", value: "1" },
        { text: "Február", value: "2" },
        { text: "Március", value: "3" },
        { text: "Április", value: "4" },
        { text: "Május", value: "5" },
        { text: "Június", value: "6" },
        { text: "Július", value: "7" },
        { text: "Augusztus", value: "8" },
        { text: "Szeptember", value: "9" },
        { text: "Október", value: "10" },
        { text: "November", value: "11" },
        { text: "December", value: "12" }
      ],
      evItems: [
        "2008",
        "2009",
        "2010",
        "2011",
        "2012",
        "2013",
        "2014",
        "2015",
        "2016",
        "2017",
        "2018",
        "2019",
        "2020",
        "2021",
        "2022",
        "2023",
        "2024",
        "2025"
      ],
      szolgaltatok: [
        { text: "Nincs megadva", value: 0 },
        { text: "Vodafone", value: 1 },
        { text: "Telenor", value: 2 },
        { text: "Telekom", value: 3 }
      ],
      szolgaltato: 0
    };
  },
  computed: {
    sortedList() {
      let sortList = this.items;
      return sortList.sort((a, b) => b.osszeg - a.osszeg);
    }
  },
  watch: {
    honap: function() {
      this.getData();
    },
    ev: function() {
      this.getData();
    },
    szolgaltato: function() {
      this.getData();
    }
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    pluszKoltseg(alapdij, parkolas, osszeg) {
      return osszeg - (alapdij + parkolas);
    },
    generateKimutatas() {
      this.$axios
        .post("/telefonok/kimutatas", {
          ev: this.ev,
          honap: this.honap,
          szolgaltato: this.szolgaltato
        })
        .then(response =>
          window.open(
            window.location.protocol == "http:"
              ? `http://${window.location.hostname}:${process.env.VUE_APP_HTTP_PORT}/kimutatasok/${response.data.fileName}`
              : `https://${window.location.hostname}:${process.env.VUE_APP_HTTPS_PORT}/kimutatasok/${response.data.fileName}`,
            "_blank"
          )
        );
    },
    getData() {
      this.items = [];
      this.isLoading = true;
      this.$axios
        .get(
          `telefonok/szamlak?targyho=${this.ev}-${this.honap}&szolgaltato=${this.szolgaltato}`
        )
        .then(response => {
          this.items = response.data;
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="stylus">

.redLine {
  color: red;
}
</style>
